<template>
    <div class="div-mestre">
        <div class="row">
            <div class="col-sm-12">
                <h2>Como funciona</h2>
                <ol>
                    <li>Digite o número</li>
                    <li>Digite sua mensagem</li>
                    <li>Clique no botão Gerar Link</li>
                    <li>Pronto!</li>
                </ol>
            </div>
        </div>
    </div>
</template>

<script>
    export default {}
</script>

<style>

</style>