<template>
    <div>
        <div>
            <nav class="navbar ">
                <span class="navbar-brand mb-0 h1"></span>
            </nav>
        </div>

        <div class="container" id="app">
            <Title></Title>
            <Form></Form>
            <HowTo></HowTo>
            <About></About>
        </div>

        <Footer></Footer>
    </div>
</template>

<script>
    import Title from './components/Title.vue'
    import Form from './components/Form.vue'
    import HowTo from './components/HowTo.vue'
    import About from './components/About.vue'
    import Footer from './components/Footer.vue'

    export default {
        name: 'App',
        components: {
            Title,
            Form,
            HowTo,
            About,
            Footer,
        }
    }
</script>

<style lang="scss">
    @import './assets/style/_variables.scss';
</style>
