<template>
    <div class="div-mestre">
        <form id="app"
              @submit="checkForm"
              action="">
            <div class="row">
                <div class="col-sm-12 col-md-6">
                    <div class="form-group"
                         v-bind:class="{sumir: mostrarSucesso}">
                        <label for="numero-whatsapp">Número Whatsapp</label>
                        <vue-phone-number-input v-model="numero"
                                                id="numero"
                                                @update="pegarNumero"
                                                size="lg"/>
                    </div>

                    <div class="form-group"
                         v-bind:class="{sumir: mostrarSucesso}">
                        <label>Mensagem</label>
                        <ckeditor :editor="editor"
                                  v-model.trim="editorData"
                                  :config="editorConfig"
                                  required="true"
                                  placeholder="Digite a mensagem aqui"></ckeditor>
                    </div>

                    <div v-bind:class="{sumir: !mostrarSucesso}">
                        <div class="input-group"
                             style="margin-bottom: 30px">
                            <div class="input-group-prepend">
                                <span class="input-group-text"><i class="material-icons">link</i></span>
                            </div>
                            <input id="link"
                                   class="form-control rounded-right"
                                   v-model="link"
                                   type="text"
                                   value=""/>

                            <div style="margin-top: 10px">
                                <h3>
                                    <img src="../assets/check-sum.svg"
                                         height="40px"
                                         width="40px"
                                         alt="Link copiado com sucesso!" style="margin-right: 5px"/>
                                    <strong>Pronto! </strong> Compartilhe onde você quiser!
                                </h3>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-sm-12 col-md-6">
                            <div v-bind:class="{sumir: mostrarSucesso}">
                                <button @click="gerarLink(editorData)"
                                        type="submit"
                                        :disabled="errors > 0"
                                        id="btnGerarLink"
                                        v-bind:class="{sumir: mostrarSucesso}"
                                        class="btn btn-lg btn-success">Gerar Link
                                </button>
                            </div>
                            <div v-bind:class="{sumir: !mostrarSucesso}">
                                <button @click="novoLink"
                                        type="submit"
                                        id="btnNovoLink"
                                        class="btn btn-lg btn-secondary">Novo Link
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>

        <b-alert
                v-model="mostrarSucesso"
                class="position-fixed fixed-bottom m-0 rounded-0"
                style="z-index: 2000; background-color: #28a745; color: #ffffff"
                variant="success"
                dismissible>
            Link copiado com sucesso!
        </b-alert>

        <b-alert
                v-model="mostrarErro"
                class="position-fixed fixed-bottom m-0 rounded-0"
                style="z-index: 2000;"
                variant="danger"
                dismissible>
            <p>
            <ul>
                <li v-for="error in errors">{{ error }}</li>
            </ul>
            </p>
        </b-alert>

        <b-alert
                v-model="mostrarErro"
                class="position-fixed fixed-bottom m-0 rounded-0"
                style="z-index: 2000;"
                variant="danger"
                dismissible>
            <p>
            <ul>
                <li v-for="error in errors">{{ error }}</li>
            </ul>
            </p>
        </b-alert>
    </div>
</template>

<script>
    import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

    export default {
        data() {
            return {
                editor: ClassicEditor,
                editorData: null,
                required: true,
                editorConfig: {
                    toolbar: {
                        items: [
                            'bold',
                            'italic',
                            // 'strikethrough',
                            // 'code',
                        ],
                    }
                },
                numero: null,
                country: null,
                link: null,
                errors: [],
                toastCount: 0,
                mostrarSucesso: false,
                mostrarErro: false,
            };
        },
        methods: {
            gerarLink(mensagem) {
                if (!this.numero || !this.editorData) {
                    this.mostrarErro = true
                    this.mostrarSucesso = false

                    return false;
                }

                this.mostrarSucesso = true
                this.mostrarErro = false

                this.link = 'https://wa.me/' + this.prepararNumero(numero) + '?text=' + encodeURIComponent(this.prepararMensagem(mensagem))

                this.copiarLink()
            },

            prepararMensagem(mensagem) {
                let mensagemTemp = '';

                // Parágrafo
                mensagemTemp = this.formatarMensagem(mensagem)

                return mensagemTemp
            },

            prepararNumero() {
                const regex = /\D/g
                const subst = ''
                const numero = this.country + this.numero

                return numero.replace(regex, subst)
            },

            formatarMensagem(mensagem) {
                const chars = {
                    '<p>': '',
                    '</p>': '\n\n',
                    '<i>': '_',
                    '</i>': '_',
                    '&nbsp;': ' ',
                    '<strong>': '*',
                    '</strong>': '*',
                    '<br>': '\n\n',
                };

                return mensagem.replace(/<p>|<\/p>|<i>|<\/i>|<strong>|<\/strong>|<br>|&nbsp;/g, m => chars[m]);
            },

            pegarNumero(data) {
                this.country = data.countryCallingCode
                this.numero = data.nationalNumber
            },

            copiarLink() {
                try {
                    const input = document.createElement('input');

                    document.body.appendChild(input);
                    input.value = this.link
                    input.select();

                    document.execCommand('copy')
                    input.remove()
                } catch (err) {
                    alert('Oops... Aconteceu algo estranho. Seu navegador é moderno?')
                }
            },

            checkForm(e) {
                if (this.numero && this.editorData) {
                    e.preventDefault()
                    return true;
                }

                this.errors = [];

                if (!this.numero) {
                    this.errors.push('O número é obrigatório.');
                }
                if (!this.editorData) {
                    this.errors.push('A mensagem é obrigatória.');
                }

                e.preventDefault()
            },

            novoLink() {
                this.numero = null
                // this.editorData = null
                this.link = null

                this.mostrarSucesso = false
            }
        }
    }
</script>

<style>
    .sumir {
        display: none;
    }
</style>