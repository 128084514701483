<template>
    <div class="div-mestre">
        <div class="row">
            <div class="col-sm-12 col-md-6">
                <h2>Sobre</h2>
                <p>Use este <b>Gerador de link para Whastsapp</b> para gerar link com uma mensagem melhor formatada.</p>
                <p>Use esta ferramente como parte da suas estratégias de marketing e para melhor o seu relacionamento
                    com os sues clientes</p>
                <p>
                    Use essa ferramenta para
                <ol>
                    <li>Cole o link na bio do seu Instragram</li>
                    <li>Cole o link na bio do seu Facebook</li>
                    <li>Cole o link na bio no seu Site</li>
                    <li>Cole o link na bio no seu Fale Conosco</li>
                </ol>
                </p>
                <p>
                    Espero siceramente que esta ferramenta seja útil para você. Ela foi desenvolvida por uma necessidade
                    particular em gerar
                    mensagens de forma mais fácil e compartilhar com outras pessoas ou colocar nos meus sistemas.
                </p>
                <p>Ficarei muito satisfeito em saber o que você achou! 😄</p>
                <p><a href="https://iagofrota.com.br/index.php/contacts/" target="_blank">Fale comigo aqui</a></p>
            </div>
        </div>
    </div>
</template>

<script>
    export default {}
</script>

<style>

</style>